@import "../theme/globals.scss";

.main-page {
  --ion-background-color: var(--main-page-color);
}

.spacing {
  padding: 1em;
}

.page-container {
  margin: 1.5em;
}

/* breakpoint = ionic xl */
@media only screen and (min-width: $breakpoint-xl) {
  .page-container {
    margin: auto;
    width: var(--xl-container-width);
  }

  .spacing {
    padding: 2em;
  }
}
