$breakpoint-xl: 1200px;
$breakpoint-lg: 992px;
$breakpoint-md: 768px;
$breakpoint-sm: 576px;

// background effect (hsl color space)
// default is 200 - 225 for nice blue effect
// another cool one could be 0 - 25 for red/orange look
$effect-hue-min: 200;
$effect-hue-max: 225;
$effect-brightness: 100%;
$effect-enable-all: 0;
$effect-enable-mobile: 0;

.split-pane {
  --side-width: 5%;
  --border: 1px solid #e0dede;
}

:root {
  // colors
  --main-page-color: #fafafa;

  --side-menu-color: #f2f2f2;
  --cards-color: #f2f2f2;

  // text colors
  --text-color-name-title: #10111f;
  --text-color-primary: #222428;
  --text-color-secondary: #92949c;
  --text-color-highlight: #4c8dff;

  // container sizes
  --xl-container-width: 60%;
  --footer-margin-top: 250px;
  --footer-margin-top-mobile: 100px;

  // footer
  --footer-height-mobile: 175px;
  --footer-height-non-mobile: 250px;
}
